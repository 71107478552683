import { first, compact, last, sortBy, map, reverse } from 'lodash'
import { isSameDay } from 'date-fns'

import {
    LatestBrowserDateTimeFormatOptions,
    LatestBrowserDateTimeFormatOptionsWithFalsey
} from '@docpace/shared-ts-types'


export const momentDashDateFormat = 'YYYY-MM-DD'
export const dateFnsDashDateFormat = 'yyyy-MM-dd' // -todo remove
export const intlDateFormat: LatestBrowserDateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
}

export function formatIntlDashDate(date: Date, _opts?: LatestBrowserDateTimeFormatOptionsWithFalsey){
    if(!date && _opts?.emptyWhenFalsey) return ''
    const dateParts = map(new Intl.DateTimeFormat('en-US', { ...intlDateFormat, ..._opts }).formatToParts(date), 'value')
    return ([dateParts[4],dateParts[0],dateParts[2]]).join('-')
}

export const momentShortDateFormat = 'MMM Do YYYY'
export const intlShortDateFormat: LatestBrowserDateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

export const momentShortDateNoYearFormat = 'MMM Do'
export const intlShortDateNoYearFormat: LatestBrowserDateTimeFormatOptions = {
    ...intlShortDateFormat,
    year: undefined
}

export const momentLongDateFormat = 'MMMM Do, YYYY'
export const intlLongDateFormat: LatestBrowserDateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

export const momentLongDateNoYearFormat = 'MMMM Do'
export const intlLongDateNoYearFormat: LatestBrowserDateTimeFormatOptions = {
    ...intlLongDateFormat,
    year: undefined
}

export const momentTimeFormat = 'LT'
export const intlShortTimeFormat: LatestBrowserDateTimeFormatOptions = {
    timeStyle: 'short'
}

export const momentHourMinuteFormat = 'h:mm'
export function formatIntlHourMinute(date: Date, _opts?: LatestBrowserDateTimeFormatOptionsWithFalsey){
    if(!date && _opts?.emptyWhenFalsey) return ''
    const opts: LatestBrowserDateTimeFormatOptions = {
        timeStyle: 'short'
    }
    const dateParts = new Intl.DateTimeFormat('en-US', { 
        ...opts, ..._opts 
    }).formatToParts(date) 
    dateParts.pop()
    dateParts.pop()
    return map(dateParts, 'value').join('')
}

export const momentSecondFormat = 'ss'
export const intlSecondsFormat: LatestBrowserDateTimeFormatOptions = {
    second: '2-digit'
}

export const momentAmPmFormat = 'A'
export function formatIntlAmPm(date: Date, _opts?: LatestBrowserDateTimeFormatOptionsWithFalsey){
    if(!date && _opts?.emptyWhenFalsey) return ''
    return last(map(new Intl.DateTimeFormat('en-US', intlShortTimeFormat).formatToParts(date), 'value')) ?? ''
}

export const momentAtTimeFormat = '[at] LT'
export function formatIntlAtTime(date: Date, _opts?: LatestBrowserDateTimeFormatOptionsWithFalsey){
    if(!date && _opts?.emptyWhenFalsey) return ''
    return `at ${new Intl.DateTimeFormat('en-US', { ...intlShortTimeFormat, ..._opts }).format(date)}`
}

export const momentAtDayTimeFormat = 'dddd [at] LT'
export function formatIntlAtDayTime(date: Date, _opts?: LatestBrowserDateTimeFormatOptionsWithFalsey){
    if(!date && _opts?.emptyWhenFalsey) return ''
    return `${new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        ..._opts
    }).format(date)} ${formatIntlAtTime(date)}`
}

export const momentLongDateTimeFormat = 'MMM Do YYYY, LT'
export const intlLongDateTimeFormat: LatestBrowserDateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
}

export const momentLongDateTimeNoYearFormat = 'MMM Do, LT'
export const intlLongDateTimeNoYearFormat: LatestBrowserDateTimeFormatOptions = {
    ...intlLongDateTimeFormat,
    year: undefined
}


export const areDatesSameDay: (
    dayA: string | number | Date,
    dayB: string | number | Date
) => boolean = (dayA, dayB) => (!!dayA && !!dayB && isSameDay(dayA, dayB))

export const sortTimestampsFromEarliestToLatest = (timestamps: (string|null|undefined)[]): string[] => {
    return sortBy(compact(timestamps), (a) => (new Date(a).getTime()))
}

export const getEarliestTimestampInSet = (timestamps: (string|null|undefined)[]): string | undefined => {
    return first(sortTimestampsFromEarliestToLatest(compact(timestamps)))
}

export const getLatestTimestampInSet = (timestamps: (string|null|undefined)[]): string | undefined => {
    return last(sortTimestampsFromEarliestToLatest(compact(timestamps)))
}

export function isWithinSeconds(timestamp, seconds) {
    const nowDate = new Date()
    const now = nowDate.getTime() + (nowDate.getTimezoneOffset() * 60000)

    const targetDate = new Date(timestamp)  
    const targetTime = targetDate.getTime() + (targetDate.getTimezoneOffset() * 60000)

    const differenceInSeconds = Math.abs((now - targetTime) / 1000);
    return differenceInSeconds <= seconds;
}

export function minutesIntoDayIntoFormattedTime(minutes:number): (string|null){
    if(!minutes) return null
    const amPm = minutes >= 12*60 ? 'PM' : 'AM'
    const hours = (minutes - minutes % 60)/60
    const displayHours = hours > 12 ? hours - 12 : hours
    const _minutes = minutes - (hours * 60)
    return `${displayHours}${_minutes > 0 ? ':'.concat(_minutes.toString().padStart(2, '0')) : ''}${amPm}`
}

export function delayPromise(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function secondsUntilNext3AM() {
    const now = new Date();

    // Create a Date object for the next 2:00 AM
    const next3AM = new Date();
    next3AM.setHours(3, 0, 0, 0);

    // If the time is already past 1:00 AM today, move to the next day
    if (now.getHours() >= 1) {
        next3AM.setDate(now.getDate() + 1);
    }
    // Calculate the difference in seconds
    const secondsUntil3AM = Math.floor((next3AM.getTime() - now.getTime())/1000);

    return secondsUntil3AM;
}