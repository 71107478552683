import { useAtom } from 'jotai'
import { DateTimeFormatOptions } from 'luxon'

import { ctxTimezoneAtom } from '@docpace/shared-react-atoms'
import { formatIntlAmPm, formatIntlAtDayTime, formatIntlAtTime, formatIntlDashDate, intlDateFormat, intlLongDateFormat, intlLongDateNoYearFormat, intlLongDateTimeFormat, intlLongDateTimeNoYearFormat, intlSecondsFormat, intlShortDateFormat, intlShortDateNoYearFormat, intlShortTimeFormat } from '@docpace/shared-ts-utils'
import { LatestBrowserDateTimeFormatOptions } from '@docpace/shared-ts-types'
import { useCallback } from 'react';
import { isValid } from 'date-fns';
import { map } from 'lodash';


export const useFormatCtxTzDateTime = () => {
    // This hook returns a function to format date objects from the context timezone
    const [ ctxTimeZone ] = useAtom(ctxTimezoneAtom) 
    const timeZone: LatestBrowserDateTimeFormatOptions["timeZone"] = ctxTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    const formatFuncs = useCallback(()=>{
            
        function makeFormatDate(
            formatOptions: DateTimeFormatOptions
        ){
            const dateTimeFormat =  new Intl.DateTimeFormat('en-US', formatOptions)
            return (date: Date)=>{
                if(!date) return ''
                date = new Date(date)
                if(!isValid(date)) return ''
                return  dateTimeFormat.format(date)
            }
        }

        return {
            formatSlashDate: makeFormatDate({ timeZone, ...intlDateFormat }),
            formatDashDate: (date: Date)=>formatIntlDashDate(date, { timeZone, ...intlDateFormat }),
            formatShortDateNoYear: makeFormatDate({ timeZone, ...intlShortDateNoYearFormat }),
            formatLongDateNoYear: makeFormatDate({ timeZone, ...intlLongDateNoYearFormat }),
            formatShortDate: makeFormatDate({ timeZone, ...intlShortDateFormat }),
            formatLongDate: makeFormatDate({ timeZone, ...intlLongDateFormat }),
            formatTime: makeFormatDate({ timeZone, ...intlShortTimeFormat }),
            formatHourMinute: (date: Date)=>{
                const dateParts = date ? new Intl.DateTimeFormat('en-US', { timeZone, timeStyle: 'short' }).formatToParts(date) : []
                dateParts.pop()
                return map(dateParts, 'value').join('')
            },
            formatSeconds:makeFormatDate({ timeZone, ...intlSecondsFormat }),
            formatAmPm: (date: Date)=>formatIntlAmPm(date, { timeZone }),
            formatAtTime: (date: Date)=>formatIntlAtTime(date, { timeZone }),
            formatAtDayTime: (date: Date)=>formatIntlAtDayTime(date, { timeZone }),
            formatLongDateTime: makeFormatDate({ timeZone, ...intlLongDateTimeFormat }),
            formatLongDateTimeNoYear: makeFormatDate({ timeZone, ...intlLongDateTimeNoYearFormat }),
        }
    }, [ ctxTimeZone ])
   
    return formatFuncs()
};