import { endOfDay, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz'

export function startOfDayInTimezone(date, timeZone) {
    // Convert the input date to the equivalent date in the specified time zone
    const zonedDate = toZonedTime(date, timeZone);
    
    // return the start of the day in that time zone
    return startOfDay(zonedDate);
}

export function endOfDayInTimezone(date, timeZone) {
    // Convert the input date to the equivalent date in the specified time zone
    const zonedDate = toZonedTime(date, timeZone);
    
    // return the end of the day in that time zone
    return endOfDay(zonedDate);
}


export function isValidTimeZone(tz: string) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        throw new Error('Time zones are not available in this environment');
    }

    try {
        Intl.DateTimeFormat(undefined, {timeZone: tz});
        return true;
    }
    catch (ex) {
        return false;
    }
}